import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/Tabs.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/register',
    component: () => import('@/views/register.vue')
  },
  {
    path: '/confirmEmailAndPhone',
    component: () => import('@/views/confirmEmailAndPhone.vue')
  },
  {
    path: '/forgotPassword',
    component: () => import('@/views/forgotPassword.vue')
  },
  {
    path: '/forgotPassword2/:hash',
    component: () => import('@/views/forgotPassword2/hash.vue')
  },
  {
    path: '/termsOfUse',
    component: () => import('@/views/termsOfUse.vue')
  },
  {
    path: '/privacyPolicy',
    component: () => import('@/views/privacyPolicy.vue')
  },
  {
    path: '/tabs/',
    name: 'tabs',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/home'
      },
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home.vue')
      },
      {
        path: 'ticket',
        component: () => import('@/views/ticket.vue')
      },
      {
        path: 'contacts',
        component: () => import('@/views/contacts.vue')
      },
      {
        path: 'myAccount',
        component: () => import('@/views/myAccount.vue')
      },
      {
        path: 'myTickets',
        component: () => import('@/views/myTickets.vue')
      },
      {
        path: 'aboutUs',
        component: () => import('@/views/aboutUs.vue')
      },
      {
        path: 'termsOfUse',
        component: () => import('@/views/termsOfUse.vue')
      },
      {
        path: 'privacyPolicy',
        component: () => import('@/views/privacyPolicy.vue')
      },
      {
        path: 'updateUser',
        component: () => import('@/views/updateUser.vue')
      },
      {
        path: 'changePassword',
        component: () => import('@/views/changePassword.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
